* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  overflow-x: hidden;
  margin: 0 auto;
}
